import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Drawer: ComponentStyleConfig = {
  baseStyle: {
    dialog: {
      maxW: 'none',
      h: 'widget.heightWidget',
      w: 'widget.widthWidget !important',
      right: '10px !important',
      left: 'auto !important',
      bottom: '0 !important',
    },
    dialogContainer: {
      maxW: 'unset',
      w: 'widget.widthWidget',
    },
  },
}
