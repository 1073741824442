export const BOT_IDS: Record<string, Record<string, string>> = {
  totalwireless: {
    dev: 'tbv_all_flows',
    prod: 'totalbyverizon',
  },
  straighttalk: {
    dev: 'st_all_flows',
    prod: 'straighttalk',
  },
  wfm: {
    dev: 'wfm_all_flows',
    prod: 'wfm',
  },
  simplemobile: {
    dev: 'sm_all_flows',
    prod: 'simplemobile',
  },
  net10wireless: {
    dev: 'nt_all_flows',
    prod: 'net10',
  },
  gosmart: {
    dev: 'gs_all_flows',
    prod: 'gosmart',
  },
  safelink: {
    dev: 'sl_all_flows',
    prod: 'safelink',
  },
  tracfone: {
    dev: 'tf_all_flows',
    prod: 'tracfone',
  },
  pageplus: {
    dev: 'pp_all_flows',
    prod: 'pageplus',
  },
}
