import { Container, Drawer, DrawerContent, DrawerHeader, useDisclosure } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { ChatConfig, intentTypes } from '../../api/dataService'
import { ANALYTICS_EVENTS } from '../../utils/analytics.interface'
import { sendAnalyticsData } from '../../utils/hooks/useAnalytics'
import { Header } from '../header/Header'
import { Widget } from './Widget'
import { WidgetIcon } from './WidgetIcon'

export const WidgetContainer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef<HTMLButtonElement>(null)
  const [intent, setIntent] = useState<intentTypes>()

  const toggleWidget = () => {
    if (isOpen) {
      onClose()
      sendAnalyticsData({
        event_category: ANALYTICS_EVENTS.CLOSE_WIDGET,
      })
    } else {
      onOpen()
      sendAnalyticsData({
        event_category: ANALYTICS_EVENTS.OPEN_WIDGET,
      })
    }
  }

  const clearIntent = () => {
    setIntent(undefined)
  }

  useEffect(() => {
    const handleOpenEvent = () => {
      onOpen()
      sendAnalyticsData({
        event_category: ANALYTICS_EVENTS.OPEN_WIDGET,
      })
    }

    const handleCloseEvent = () => {
      onClose()
      sendAnalyticsData({
        event_category: ANALYTICS_EVENTS.CLOSE_WIDGET,
      })
    }

    window.addEventListener('refactory:chat-widget:open', handleOpenEvent)
    window.addEventListener('refactory:chat-widget:close', handleCloseEvent)

    return () => {
      window.removeEventListener('refactory:chat-widget:open', handleOpenEvent)
      window.removeEventListener('refactory:chat-widget:close', handleCloseEvent)
    }
  }, [onOpen, onClose])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const isShowBot = queryParams.get('showBot') === 'true'

    if (isShowBot) {
      toggleWidget()
    }
    window.chatWidget = {
      open: (chatConfig: ChatConfig) => {
        if (chatConfig?.intent) setIntent(chatConfig.intent)
        window.dispatchEvent(new CustomEvent('refactory:chat-widget:open'))
      },
      close: () => window.dispatchEvent(new CustomEvent('refactory:chat-widget:close')),
    }

    return () => {
      delete window.chatWidget
    }
  }, [])

  return (
    <Container variant='widget_container'>
      {isOpen && (
        <Drawer
          placement='bottom'
          isOpen={isOpen}
          onClose={onClose}
          finalFocusRef={btnRef}
          trapFocus={false}
          lockFocusAcrossFrames={true}
          size={'widget.md'}
          blockScrollOnMount={false}
        >
          <DrawerContent
            data-testid='container_bot'
            w={['100%', 'widget.widthWidget']}
            height={['100%', 'widget.heightWidget']}
            top={['0', 'unset']}
            left={['0', 'auto !important']}
            right={['0', '10px']}
          >
            <DrawerHeader p={0}>
              <Header onClose={toggleWidget} />
            </DrawerHeader>
            <Widget
              intent={intent}
              clearIntent={clearIntent}
            />
          </DrawerContent>
        </Drawer>
      )}
      <WidgetIcon onClick={toggleWidget} />
    </Container>
  )
}
