import * as Sentry from '@sentry/react'
import { ErrorBoundary } from '@sentry/react'

import { render } from 'preact'
import { Suspense } from 'preact/compat'
import { I18nextProvider } from 'react-i18next'
import { App } from './App'
import i18n from './i18n/i18n'
import './index.css'

const LoadingFallback = () => <div>Loading translations...</div>

declare global {
  interface Window {
    initializeWidget: () => void
  }
}

if (import.meta.env.VITE_INSTANCE_ENVIRONMENT !== 'dev') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_INSTANCE_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

function renderWidget() {
  const container = document.getElementById('widget-container')
  if (container) {
    render(
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<LoadingFallback />}>
          <ErrorBoundary fallback={<p>Something went wrong. Please try again later.</p>}>
            <App />
          </ErrorBoundary>
        </Suspense>
      </I18nextProvider>,
      container
    )
  }
}

if (typeof window !== 'undefined') {
  window.initializeWidget = renderWidget
}

renderWidget()
