import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Container: ComponentStyleConfig = {
  baseStyle: {
    m: 0,
    p: 0,
  },
  variants: {
    widget_container: {
      position: 'fixed',
      w: 'auto',
      bottom: 0,
      right: 0,
    },
    widget_wrapper: {
      display: 'flex',
      h: '100%',
      alignItems: 'center',
      flexDir: 'column',
      justifyContent: 'center',
      flex: 1,
      minH: 1,
    },
    input_wrapper: {
      display: 'flex',
      px: 2,
      h: 8,
      flex: 1,
      bgColor: 'white',
      borderRadius: 5,
      alignItems: 'center',
    },
    header_wrapper: {
      display: 'flex',
      h: 16,
      bg: 'widget.header',
      p: 4,
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTop: 'headerBorderTop',
      borderBottom: 'headerBorderBottom',
    },
    answers_wrapper: {
      '&::-webkit-scrollbar': {
        width: 3,
        borderRadius: 1,
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: 2,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 2,
        backgroundColor: 'widget.gray.200',
      },
      display: 'flex',
      flexDir: 'column',
      '& > :first-of-type': {
        marginTop: 'auto',
      },
      gap: 6,
      py: 6,
      paddingLeft: 4,
      paddingRight: 1,
      w: '100%',
      height: '100%',
      overflowY: 'scroll',
    },
    circle_container: {
      pos: 'absolute',
      display: 'flex',
      justifyContent: 'space-between',
      w: 7,
      height: 2,
      top: -3,
    },
  },
}
