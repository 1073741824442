import { Button, Flex } from '@chakra-ui/react'

export const PaymentButton = () => {
  const width = 300
  const height = 400
  const screenWidth = window.innerWidth
  const screenHeight = window.innerHeight
  const left = (screenWidth - width) / 2
  const top = (screenHeight - height) / 2

  const onClick = () => {
    window.open(
      import.meta.env.VITE_PAYMENT_FORM_URL,
      'payment-form',
      `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`,
    )
  }

  return (
    <Flex
      w='100%'
      justifyContent={'center'}
    >
      <Button
        variant={'customer_option'}
        onClick={onClick}
      >
        To pay
      </Button>
    </Flex>
  )
}
