import { ChakraProvider } from '@chakra-ui/react'
import { useContext } from 'preact/hooks'
import './App.css'
import { theme } from './components/styles/theme'
import { WidgetContainer } from './components/widget/WidgetContainer'
import { ButtonsProvider } from './context/buttons'
import { ConfCtx } from './context/conf'
import { MessagesProvider } from './context/messages'
import { useAnalytics } from './utils/hooks/useAnalytics'

export const App = () => {
  const confSt = useContext(ConfCtx)
  useAnalytics()

  return (
    <div
      className='App'
      id='chat_widget_app'
    >
      <ChakraProvider
        theme={theme(confSt.retailer)}
        cssVarsRoot={'#chat_widget_app'}
      >
        <MessagesProvider>
          <ButtonsProvider>
            <WidgetContainer />
          </ButtonsProvider>
        </MessagesProvider>
      </ChakraProvider>
    </div>
  )
}
