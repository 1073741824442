interface ApiRequestPayload extends Omit<RequestInit, 'body'> {
  body?: object
  baseURL?: string
}

const request = async <T>(url: string, payload: ApiRequestPayload): Promise<T> => {
  const baseURL = payload.baseURL
  const headers = {
    'Content-Type': 'application/json',
    ...payload.headers,
  }

  const params: RequestInit = {
    ...payload,
    headers,
    body: payload.body ? JSON.stringify(payload.body) : undefined,
  }

  const res = await fetch(`${baseURL}/${url}`, params)
  const data = await res.json()

  if (!res.ok) {
    throw { message: data.message, status: res.status }
  }

  return data
}

export const post = async <T>(
  url: string,
  body: object,
  headers?: HeadersInit,
  baseURL?: string
) => {
  return request<T>(url, { method: 'POST', body, headers, baseURL })
}

export const get = async <T>(url: string, headers?: HeadersInit, baseURL?: string) => {
  return request<T>(url, { method: 'GET', headers, baseURL })
}
