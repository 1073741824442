import { Box, Button, Container, Flex, Image, Spinner, Text } from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'preact/hooks'
import { Fragment } from 'preact/jsx-runtime'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import { v4 as uuidv4 } from 'uuid'
import {
  intentTypes,
  MessageToDisplay,
  MessageToSendBot,
  MODE,
  Payload,
} from '../../api/dataService'
import { ButtonType, useButtonsContext } from '../../context/buttons'
import { useMessagesContext } from '../../context/messages'
import { ANALYTICS_EVENTS } from '../../utils/analytics.interface'
import { convertLinksToHtml } from '../../utils/helper'
import { useAgent } from '../../utils/hooks/useAgent'
import { sendAnalyticsData } from '../../utils/hooks/useAnalytics'
import { useBot } from '../../utils/hooks/useBot'
import { getLSItem, setLSItem } from '../../utils/localStorage'
import { Footer } from '../dialog/Footer'
import { PaymentButton } from '../dialog/PaymentButton'
import { AgentMessageIcon, BotIcon, UserIcon } from '../styles/components/icons'

const paymentFormUrl = import.meta.env.VITE_PAYMENT_FORM_URL

const getIcon = (from: (typeof MODE)[keyof typeof MODE]) => {
  switch (from) {
    case MODE.AGENT:
      return <AgentMessageIcon color={'widget.text'} />
    case MODE.BOT:
      return (
        <BotIcon
          boxSize={8}
          color='widget.botIcon'
        />
      )
    default:
      return <UserIcon />
  }
}

const BUTTON_TYPE = {
  START_OVER: 'Start over',
  QUIT: 'quit',
}

interface ComponentProps {
  intent?: intentTypes
  clearIntent: () => void
}

export const Widget = ({ intent, clearIntent }: ComponentProps) => {
  const { t } = useTranslation()
  const messageContainerRef = useRef<HTMLDivElement>(null)
  const answersRef = useRef<HTMLDivElement>(null)
  const lastMessageRef = useRef<HTMLDivElement>(null)
  const lastButtonsRef = useRef<HTMLButtonElement>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isTextInputDisabled, setIsTextInputDisabled] = useState<boolean>(true)
  const [isNewSession, setIsNewSession] = useState<boolean>()
  const {
    isAgentMode,
    sendMessage: sendMessageToAgent,
    closeAgent,
    handleAgent,
    fetchAgentMessages,
  } = useAgent()
  const {
    sendMessage: sendMessageToBot,
    isTextInputDisabled: isBotDisabled,
    placeholder,
    isPrimaryBtn,
    isAgentTriggered,
    setIsAgentTriggered,
  } = useBot()
  const { addMessage, messages } = useMessagesContext()
  const { buttons } = useButtonsContext()
  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 1.25,
    slidesToScroll: 1,
    distance: 10,
  }

  const sendMessage = useCallback(
    async (
      msg: string,
      { isMessageShown } = { isMessageShown: false },
      type: (typeof MessageToSendBot)[keyof typeof MessageToSendBot] = MessageToSendBot.TEXT,
      payload = {}
    ) => {
      setIsLoading(true)
      const userId: string = getLSItem('user_id') || ''
      // Handle session expiration by checking for the presence of `user_id`
      if (!userId) {
        if (isAgentMode) {
          closeAgent()
        }
        const new_user = uuidv4()
        setLSItem('user_id', new_user, 0.5)
        setIsNewSession(true)
        return
      }
      isAgentMode
        ? await sendMessageToAgent(msg)
        : await sendMessageToBot({ text: msg, type, payload }, { isMessageShown })
      clearIntent()
      setIsLoading(false)
    },
    [isAgentMode]
  )

  const handleBack = async () => {
    setIsLoading(true)
    if (!getLSItem('user_id')) {
      const new_user = uuidv4()
      setLSItem('user_id', new_user, 0.5)
    }
    sendAnalyticsData({
      name: ANALYTICS_EVENTS.RESET,
      event_category: ANALYTICS_EVENTS.BUTTON_CLICK,
    })
    await sendMessage(BUTTON_TYPE.START_OVER, { isMessageShown: true }, MessageToSendBot.TEXT, {})

    setIsLoading(false)
    closeAgent()
  }

  const handleOptionSelect = async (button: ButtonType) => {
    setIsLoading(true)
    if (button.value === BUTTON_TYPE.START_OVER && !getLSItem('user_id')) {
      closeAgent()
    }
    sendAnalyticsData({
      name: button.title,
      event_category: ANALYTICS_EVENTS.BUTTON_CLICK,
    })
    await sendMessage(button.value, { isMessageShown: true }, MessageToSendBot.TEXT, {})
    setIsLoading(false)
  }

  const scrollIntoView = useCallback(() => {
    // Scroll to the bottom of the chat when messages update
    if (!lastButtonsRef.current && !lastMessageRef.current) return

    const targetElement = lastButtonsRef.current || lastMessageRef.current
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [])

  const handleLinkClick = (event: MouseEvent) => {
    const target = event.target as HTMLAnchorElement
    const url = target.href
    sendAnalyticsData({
      event_category: ANALYTICS_EVENTS.LINK_CLICK,
      url,
    })
  }

  const getPaymentButton = (text: string) => {
    const isPaymentForm = text.includes(paymentFormUrl)
    if (isPaymentForm) {
      return <PaymentButton />
    }
    return <></>
  }

  const formatBoldText = ({ text, isAgentMode }: { text: string; isAgentMode: boolean }) => {
    let formattedText = isAgentMode ? convertLinksToHtml(text) : text

    // Replace text inside ** with <b> tags
    formattedText?.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
    return { __html: formattedText }
  }

  useEffect(() => {
    setIsTextInputDisabled(!isAgentMode && isBotDisabled)
  }, [isBotDisabled, isAgentMode])

  useEffect(() => {
    const user = getLSItem('user_id') || uuidv4()

    if (user !== getLSItem('user_id')) {
      setLSItem('user_id', user, 0.5)
    }
    if (!messages || !messages.length || intent || isNewSession) {
      const textToStartConversation = !messages || !messages.length || isNewSession ? 'hi' : ''
      const queryParams = new URLSearchParams(window.location.search)
      const previousBotsessionId = queryParams.get('previousBotsessionId')
      const language = document.documentElement.lang.includes('es') ? 'es' : 'en'

      const payload: Payload = { language }
      if (intent) payload.intent = intent
      if (previousBotsessionId) payload.previousBotsessionId = previousBotsessionId
      sendMessage(
        textToStartConversation,
        { isMessageShown: false },
        MessageToSendBot.PROACTIVE_TRIGGER,
        payload
      )
      return () => {
        clearIntent()
        setIsNewSession(false)
      }
    }
  }, [intent, isNewSession])

  /**
   *Fetch agent messages every 5 seconds if an engagement ID exists, updating the message state.
   */
  useEffect(() => {
    const updateMessages = async () => {
      const newMessages = await fetchAgentMessages()
      if (newMessages.length > 0) {
        addMessage([...newMessages])
      }
    }

    updateMessages()
    const intervalId = setInterval(updateMessages, 5000)
    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    if (isAgentTriggered) {
      const connectToAgent = async () => {
        await handleAgent()
        await sendMessageToBot(
          {
            type: MessageToSendBot.SEND_CHAT_DATA,
            payload: {
              lpEngagementId: getLSItem('engagement_id') || '',
            },
          },
          { isMessageShown: false }
        )
      }
      connectToAgent()
    }

    return () => {
      setIsAgentTriggered(false)
    }
  }, [isAgentTriggered])

  /**
   * Stores the `messages` and `buttons` in storage for 3 hours
   */
  useEffect(() => {
    scrollIntoView()
    setLSItem('messages', { messages: JSON.stringify(messages) }, 3)
    // If no messages, but there's a valid session (user_id), restart conversation
    if (!messages.length && !getLSItem('messages') && getLSItem('user_id')) {
      sendMessage(BUTTON_TYPE.START_OVER)
    }
  }, [messages])

  useEffect(() => {
    scrollIntoView()
    setLSItem('buttons', { buttons: JSON.stringify(buttons), isPrimaryCTA: isPrimaryBtn }, 3)
  }, [buttons])

  // Add event listeners to links to handle analytics for link clicks
  useEffect(() => {
    if (messageContainerRef.current) {
      const links = messageContainerRef.current.querySelectorAll('a')

      links.forEach((link) => {
        link.addEventListener('click', handleLinkClick)
      })

      return () => {
        links.forEach((link) => {
          link.removeEventListener('click', handleLinkClick)
        })
      }
    }
  }, [messageContainerRef.current])

  useEffect(() => {
    const container = messageContainerRef.current
    if (!container) return

    const observer = new MutationObserver(() => {
      scrollIntoView()
    })

    observer.observe(container, {
      childList: true, // Observes direct children addition/removal
      subtree: true, // Observes deeper changes in the DOM tree
    })

    return () => observer.disconnect()
  }, [scrollIntoView])

  return (
    <Container
      variant='widget_wrapper'
      ref={messageContainerRef}
    >
      <Container
        variant={'answers_wrapper'}
        overflowX={'hidden'}
        ref={answersRef}
      >
        {messages.map((msg, index) =>
          msg.type === MessageToDisplay.CAROUSEL ? (
            <Slider {...settings}>
              {msg.items?.map((item, index) => (
                <Fragment key={index}>
                  <Box
                    marginRight={4}
                    minW={60}
                    minH={80}
                    borderRadius='20'
                    overflow='auto'
                    borderColor='widget.gray.100'
                    bg='widget.primary'
                    position='relative'
                  >
                    <Image
                      position='absolute'
                      bottom='0'
                      right='0'
                      alt={'Carousel item'}
                      src={item.image}
                    />
                    <Box p='3'>
                      <Box
                        fontWeight='700'
                        fontSize='widget.sm'
                        as='h4'
                        lineHeight={6}
                        noOfLines={1}
                      >
                        {item.title}
                      </Box>
                      <Box
                        noOfLines={12}
                        dangerouslySetInnerHTML={formatBoldText({
                          text: item.subtitle,
                          isAgentMode: msg.from === MODE.AGENT,
                        })}
                      ></Box>
                    </Box>
                  </Box>
                </Fragment>
              ))}
            </Slider>
          ) : (
            <Box>
              <Flex
                key={index}
                alignItems='flex-end'
                ml={msg.from === MODE.USER ? 7 : 0}
                flexDirection={msg.from === MODE.USER ? 'row-reverse' : 'row'}
              >
                {getIcon(msg.from)}
                <Text
                  variant={msg.from === MODE.USER ? 'user_message' : 'message'}
                  dangerouslySetInnerHTML={formatBoldText({
                    text: msg.text,
                    isAgentMode: msg.from === MODE.AGENT,
                  })}
                  ref={index === messages.length - 1 ? lastMessageRef : undefined}
                />
              </Flex>
              {getPaymentButton(msg.text)}
            </Box>
          )
        )}
        {isLoading ? (
          <Flex
            justifyContent='center'
            alignItems='center'
          >
            <Spinner />
          </Flex>
        ) : (
          !getLSItem('engagement_id') && (
            <Flex
              wrap={'wrap'}
              justifyContent={'center'}
              gap={2}
            >
              {buttons.map((button, index) => (
                <Button
                  variant={index == 0 && isPrimaryBtn ? 'customer_option' : 'option'}
                  key={button.value}
                  aria-label={t('select_option')}
                  onClick={() => handleOptionSelect(button)}
                  ref={index === buttons.length - 1 ? lastButtonsRef : undefined}
                >
                  {button.title}
                </Button>
              ))}
            </Flex>
          )
        )}
      </Container>
      <Footer
        onBack={handleBack}
        isTextInputDisabled={isTextInputDisabled || isLoading}
        onSend={sendMessage}
        placeholder={placeholder}
      />
    </Container>
  )
}
