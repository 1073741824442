export const customers = [
  'totalwireless',
  'wfm',
  'gosmart',
  'net10wireless',
  'pageplus',
  'straighttalk',
  'tracfone',
  'safelink',
  'simplemobile',
  'whitelabel',
] as const

export type Customer = (typeof customers)[number]

export type CustomerConfig = {
  retailer: Customer
}

export const isCustomer = (a: Customer) => customers.includes(a)
