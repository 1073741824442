const paymentFormUrl = import.meta.env.VITE_PAYMENT_FORM_URL

export const convertLinksToHtml = (message: string) => {
  const urlRegex = /https?:\/\/[^\s]+/g
  return message.replace(urlRegex, (url) => {
    if (url.startsWith(paymentFormUrl)) {
      return ''
    }
    return `<a href="${url}">${url}</a>`
  })
}
