import {
  CreateEngagementRes,
  GetEngagementRes,
  GetMessagesRes,
  SendMessageRes,
} from '../interfaces/agent.interface'
import { BOT_IDS } from '../interfaces/bot.interface'
import { get, post } from '../utils/api'
import { getLSItem, setLSItem } from '../utils/localStorage'
import { AuthData, BotMsg, BotRequestData } from './dataService'

const queryParams = new URLSearchParams(window.location.search)
const botId = queryParams.get('botId')
const customerName = queryParams.get('customer')
export const customer = customerName || import.meta.env.VITE_CUSTOMER
const env = import.meta.env.VITE_INSTANCE_ENVIRONMENT
const botname = botId || BOT_IDS[customer][env]
const authHost = import.meta.env.VITE_AUTH_HOST
const agentUrl = import.meta.env.VITE_AGENT_URL
const currentLangForAgent = document.documentElement.lang.includes('es') ? 'Spa' : 'Eng'

type APIError = {
  res: {
    status: number
  }
}
const createUrl = ({ user }: { user: string }) => `api/v1/bots/${botname}/converse/${user}/secured`

const fetchAuthToken = async (): Promise<string | null> => {
  try {
    const authData = await post<AuthData>(`/auth`, {}, {}, authHost)
    const authToken = authData.jwt
    const expTime = authData.exp / 60 / 60 / 1000 // convert milliseconds to hours

    setLSItem('auth_token', authToken, expTime)

    return authToken
  } catch (error) {
    console.error('Error fetching auth token:', error)
    return null
  }
}

const sendMessageAPI = async (requestData: BotRequestData, authToken: string) => {
  const user = getLSItem('user_id') || ''
  const url = createUrl({ user })
  const data = await post<{ responses: BotMsg[] }>(
    url,
    requestData,
    {
      Authorization: `Bearer ${authToken}`,
    },
    import.meta.env.VITE_BOT_HOST
  )
  return data.responses
}

export const sendMessageRequest = async (requestData: BotRequestData) => {
  let authToken = getLSItem('auth_token') || (await fetchAuthToken()) || ''
  try {
    return await sendMessageAPI(requestData, authToken)
  } catch (error: unknown) {
    const apiError = error as APIError
    if (apiError?.res?.status === 401) {
      authToken = (await fetchAuthToken()) || ''
      return sendMessageAPI(requestData, authToken)
    }
    throw error
  }
}

export const getAgentMessages = async (): Promise<GetMessagesRes> => {
  const engagementID: string = getLSItem('engagement_id') || ''
  return await get(`messages?engagementID=${engagementID}`, {}, agentUrl)
}

export const sendAgentMessage = async (messageText: string): Promise<SendMessageRes> => {
  const engagementID: string = getLSItem('engagement_id') || ''
  return await post(`message`, { engagementID, messageText }, {}, agentUrl)
}

export const createEngagement = async (): Promise<CreateEngagementRes> => {
  const user_id: string = getLSItem('user_id') || ''
  return await post(
    `engage?client=${customer}&lang=${currentLangForAgent}&status=Sales&userId=${user_id}&bpEngagementId=${user_id}`,
    {},
    {},
    agentUrl
  )
}

export const getEgagement = async (): Promise<GetEngagementRes> => {
  const engagementID: string = getLSItem('engagement_id') || ''
  return await get(`engage?engagementID=${engagementID}`, {}, agentUrl)
}
