interface LocalStorageItem<T> {
  data: T
  lastUpdate?: number
  expTime?: number
}

// Utility to check if an item is expired
const hasExpired = (conversation: LocalStorageItem<string>) => {
  if (!conversation.lastUpdate || !conversation.expTime) return false
  const expiration_period = conversation.expTime * 60 * 60 * 1000 // hours in milliseconds
  const current_time = Date.now()
  return current_time - conversation.lastUpdate > expiration_period
}

/**
 * Helper function to safely parse JSON.
 * @param data - Data to parse.
 * @returns Parsed data or the original value if parsing fails.
 */
const safelyParseJSON = (data: any): any => {
  try {
    return JSON.parse(data)
  } catch {
    return data
  }
}

export const getLSItem = <T = string>(key: string, dataProperty?: string): T | null => {
  // Regular expression to validate if the value is JSON or a primitive
  const jsonRegex =
    /^(?:\s*[\[\{].*[\]\}]\s*|\s*(?:true|false|null|[+-]?\d+|"([^"\\]|\\["\\/bfnrt]|\\u[0-9a-fA-F]{4})*")\s*)$/

  const itemFromLS = localStorage.getItem(key)
  if (!itemFromLS) return null

  let parsedItem
  try {
    parsedItem = JSON.parse(itemFromLS)
  } catch {
    parsedItem = itemFromLS
  }

  if (jsonRegex.test(parsedItem) && typeof parsedItem === 'object') {
    const value = safelyParseJSON(
      dataProperty ? parsedItem.data[dataProperty] : parsedItem.data[key]
    )

    if (hasExpired(parsedItem)) {
      removeLSItem(key)
      return null
    }
    return value || null
  } else {
    return parsedItem as T
  }
}

export const setLSItem = (
  key: string,
  value: Record<string, unknown> | unknown,
  expTimeInHours?: number
): void => {
  if (!expTimeInHours) {
    localStorage.setItem(key, JSON.stringify(value))
    return
  }

  const data =
    typeof value === 'string'
      ? { [key]: value }
      : {
          ...(value as object),
        }

  const item: LocalStorageItem<unknown> = {
    data,
    lastUpdate: Date.now(),
    expTime: expTimeInHours,
  }

  localStorage.setItem(key, JSON.stringify(item))
}

export const removeLSItem = (key: string): void => {
  localStorage.removeItem(key)
}

export const clearLS = (): void => {
  localStorage.clear()
}
