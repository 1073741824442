import { createContext } from 'react'
import { customer } from '../api/direct'
import { customersObj } from '../customer-config'
import { Customer, isCustomer } from '../interfaces/customer.interface'

const defaultCustomer = customer as Customer

if (!isCustomer(defaultCustomer)) throw new Error(`unsupported customer '${defaultCustomer}'`)

const defaultCtx = customersObj[defaultCustomer]

export const ConfCtx = createContext(defaultCtx)
