import { MODE } from '../api/dataService'

export const ANALYTICS_EVENTS = {
  EVENT: 'UIaaS',
  BUTTON_CLICK: 'button click',
  LINK_CLICK: 'link click',
  CONNECT_TO_AGENT: 'connect to agent',
  RESET: 'reset',
  SEND: 'send',
  QUIT_FROM_USER: 'agent quit form user',
  QUIT_FROM_AGENT: 'agent close converation',
  FAILED: 'agent close converation',
  AGENT_UNAVAILABLE: 'agents are unavailable',
  CLOSE_AGENT: ' chat with the agent is closed',
  UNAVAILABLE_CONNECT_AGENT: 'unable to connect with an agent',
  CLOSE_WIDGET: 'close widget',
  OPEN_WIDGET: 'open widget',
  MESSAGE: 'Message',
}
export type Analytics = {
  event_category: (typeof ANALYTICS_EVENTS)[keyof typeof ANALYTICS_EVENTS]
  name?: string
  url?: string
  message?: string
  from?: (typeof MODE)[keyof typeof MODE]
  to?: (typeof MODE)[keyof typeof MODE]
}
