import { Customer, CustomerConfig } from './interfaces/customer.interface'

export const customersObj: Record<Customer, CustomerConfig> = {
  totalwireless: {
    retailer: 'totalwireless',
  },
  wfm: {
    retailer: 'wfm',
  },
  gosmart: {
    retailer: 'gosmart',
  },
  net10wireless: {
    retailer: 'net10wireless',
  },
  pageplus: {
    retailer: 'pageplus',
  },
  straighttalk: {
    retailer: 'straighttalk',
  },
  tracfone: {
    retailer: 'tracfone',
  },
  safelink: {
    retailer: 'safelink',
  },
  simplemobile: {
    retailer: 'simplemobile',
  },
  whitelabel: {
    retailer: 'whitelabel',
  },
} as const
