import { Container, IconButton, Tooltip } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { CloseIcon } from '../styles/components/icons'
import { Logo } from './Logo'

type Props = {
  onClose: () => void
}

export const Header = ({ onClose }: Props) => {
  const { t } = useTranslation()

  return (
    <Container variant='header_wrapper'>
      <Logo />
      <Tooltip label={t('close_icon')}>
        <IconButton
          variant={'none'}
          aria-label={t('close_widget')}
          onClick={onClose}
          icon={<CloseIcon color={'widget.headerIcon'} />}
        />
      </Tooltip>
    </Container>
  )
}
