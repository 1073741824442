import { collectAnalyticsData, initializeAnalytics } from '@devrefactory-tracfone/analytics-lib'
import { useEffect } from 'preact/hooks'
import { customer } from '../../api/direct'
import { Analytics, ANALYTICS_EVENTS } from '../analytics.interface'

const iframeHost = import.meta.env.VITE_IFRAME_HOST
const environment = import.meta.env.VITE_ANALYTICS_ENV
const analyticsURL = import.meta.env.VITE_ANALYTICS_URL

export const useAnalytics = () => {
  const config = {
    iframeHost,
    customer,
    analyticsURL,
    environment,
    debug: true,
  }
  useEffect(() => {
    console.log('analytics')
    initializeAnalytics(config)
  }, [])
}

export const sendAnalyticsData = (data: Analytics) => {
  collectAnalyticsData({ ...data, event: ANALYTICS_EVENTS.EVENT })
}
