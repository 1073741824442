export const BaseMessage = {
  TEXT: 'text',
} as const

export const MessageToDisplay = {
  ...BaseMessage,
  CAROUSEL: 'carousel',
} as const

export const MessageToSendBot = {
  ...BaseMessage,
  PAYLOAD: 'payload',
  PROACTIVE_TRIGGER: 'proactive-trigger',
  SEND_CHAT_DATA: 'send-chat-data',
}

export const MODE = {
  BOT: 'bot',
  AGENT: 'agent',
  USER: 'user',
}

export const nodeType = {
  CONNECT_AGENT: '4250_im_connectingAgent',
}

export type BotMsgBasic = {
  text: string
  type: (typeof choiceType)[keyof typeof choiceType]
  placeholder: string
  disableFreeText: boolean
  primaryCTA: boolean
  url: string
  items: CarouselItem[]
  node: string
}

export type BotMsgChoice = {
  type: (typeof choiceType)[keyof typeof choiceType]
  text: string
  choices: Array<{
    title: string
    value: string
  }>
  disableFreeText: boolean
  placeholder: string
  primaryCTA: boolean
  node: string
}

export type CarouselItem = {
  actions: CarouselActions[]
  image: string
  markdown: boolean
  subtitle: string
  title: string
}

export type CarouselActions = {
  action: string
  title: string
  url?: string
  text?: string
  payload?: string
}

export type AuthData = {
  jwt: string
  exp: number
}

export type BotMsg = BotMsgBasic | BotMsgChoice

export const choiceType = {
  SINGLE_CHOICE: 'single-choice',
  BACKGROUND_NAVIGATION: 'background-navigation',
  CAROUSEL: 'carousel',
  OFF_PAGE_REFERENCE: 'off-page-reference',
} as const

export enum intentTypes {
  USAGE = 'usage',
  CUSTOMER = 'customer',
  BECOME_CUSTOMER = 'becomeCustomer',
  MORE_INFORMATION = 'moreInformation',
  ACTIVATE = 'activate',
  PORT = 'port',
  UPGRADE = 'upgrade',
  TECHNICAL_SUPPOR = 'technicalSupport',
  REFILL = 'refill',
  DUE_DATE = 'dueDate',
  ADD_LINE = 'addLine',
  REWARDS = 'rewards',
}

export type Payload = {
  language?: 'en' | 'es'
  intent?: intentTypes
  previousBotsessionId?: string
  lpEngagementId?: string
}

export type ChatConfig = {
  intent?: intentTypes
}

export type BotRequestData = {
  text?: string
  payload: Payload
  type: (typeof MessageToSendBot)[keyof typeof MessageToSendBot]
}

export const isBasic = (a: unknown): a is BotMsgBasic =>
  (!!(a as BotMsgBasic).text || (a as BotMsgBasic).type == choiceType.CAROUSEL) &&
  (a as BotMsgBasic).node !== nodeType.CONNECT_AGENT
export const isRedirectionType = (a: unknown): a is BotMsgBasic =>
  (a as BotMsgBasic).type == choiceType.BACKGROUND_NAVIGATION ||
  (a as BotMsgBasic).type == choiceType.OFF_PAGE_REFERENCE
export const isSingleChoice = (a: unknown): a is BotMsgChoice =>
  (a as BotMsgChoice).type === choiceType.SINGLE_CHOICE &&
  Array.isArray((a as BotMsgChoice).choices)
