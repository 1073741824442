import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Badge: ComponentStyleConfig = {
  variants: {
    unread_messages: {
      position: 'absolute',
      width: 3,
      height: 3,
      top: 4,
      right: 3,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 'full',
      bg: 'red.100',
      color: 'white',
      fontSize: '3xs',
    },
  },
}
