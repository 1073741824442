import { ComponentStyleConfig } from '@chakra-ui/react'

export const Popover: ComponentStyleConfig = {
  variants: {
    custom: {
      content: {
        position: 'absolute',
        bg: 'white',
        borderColor: 'widget.gray.700',
        borderRadius: '2px',
        padding: 1,
        w: 36,
        top: -16,
        right: -36,
      },
    },
  },
}
