import { useRef, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import { MessageToDisplay, MessageToSendBot, MODE } from '../../api/dataService'
import {
  createEngagement,
  getAgentMessages,
  getEgagement,
  sendAgentMessage,
} from '../../api/direct'
import { useButtonsContext } from '../../context/buttons'
import { Message, useMessagesContext } from '../../context/messages'
import {
  CreateEngagementRes,
  GetEngagementRes,
  GetMessagesRes,
} from '../../interfaces/agent.interface'
import { ANALYTICS_EVENTS } from '../../utils/analytics.interface'
import { getLSItem, removeLSItem, setLSItem } from '../../utils/localStorage'
import { sendAnalyticsData } from './useAnalytics'

const BUTTON_TYPE = {
  QUIT: 'quit',
  START_OVER: 'Start over',
}

type ErrorResponse = {
  status?: number
  message: string
}

export const useAgent = () => {
  const { t } = useTranslation()

  const [isAgentMode, setIsAgentMode] = useState<boolean>(!!getLSItem('engagement_id'))
  const { addMessage } = useMessagesContext()
  const { addButtons } = useButtonsContext()

  const isRequestInFlightRef = useRef(false)

  const isAgentOpen = async (): Promise<boolean> => {
    const engagementId = getLSItem('engagement_id')
    if (!engagementId) {
      return false
    }

    try {
      const res: GetEngagementRes = await getEgagement()
      return res.isOpen
    } catch (error) {
      console.error('Failed to check agent engagement:', error)
      return false
    }
  }

  const sendMessage = async (msg: string, { isMessageShown } = { isMessageShown: true }) => {
    const isOpen = await isAgentOpen()
    if (!isOpen) {
      handleClosedConversation(true)
      return
    }

    try {
      await sendAgentMessage(msg)
      if (isMessageShown) {
        addMessage([{ text: msg, type: MessageToDisplay.TEXT, from: MODE.USER }])
      }
      if (msg === BUTTON_TYPE.QUIT) {
        handleClosedConversation(false, { isMessageShown })
      } else {
        sendAnalyticsData({
          event_category: ANALYTICS_EVENTS.MESSAGE,
          message: msg,
          from: MODE.USER,
          to: MODE.AGENT,
        })
      }
    } catch (error) {
      console.error('Failed to send agent message:', error)
    }
  }

  const handleClosedConversation = (
    isClosed: boolean = false,
    { isMessageShown } = { isMessageShown: true }
  ) => {
    removeLSItem('engagement_id')
    setIsAgentMode(false)

    // Check whether the chat has already been closed by the agent or the user.
    if (isClosed) {
      addMessage([
        { text: t('closed_conversation_agent'), type: MessageToDisplay.TEXT, from: MODE.BOT },
      ])
      sendAnalyticsData({
        event_category: ANALYTICS_EVENTS.QUIT_FROM_AGENT,
      })
      addButtons([{ title: t('connect'), value: 'agent' }])
    } else if (isMessageShown) {
      addMessage([
        { text: t('closed_conversation_user'), type: MessageToDisplay.TEXT, from: MODE.BOT },
      ])
      sendAnalyticsData({
        event_category: ANALYTICS_EVENTS.QUIT_FROM_USER,
      })
      addButtons([{ title: t('start_over'), value: BUTTON_TYPE.START_OVER }])
    }
  }

  const handleWaitingInQueue = (res: CreateEngagementRes) => {
    if (res.waitingInQueue >= 1) {
      addMessage([
        {
          text: t('waiting_in_queue', { number: res.waitingInQueue }),
          type: MessageToDisplay.TEXT,
          from: MODE.BOT,
        },
      ])
    }
    if (res.message) {
      addMessage([{ text: res.message, type: MessageToDisplay.TEXT, from: MODE.BOT }])
    }
  }

  const handleAgent = async () => {
    if (getLSItem('engagement_id')) {
      const isOpen = await isAgentOpen()
      setIsAgentMode(isOpen)
      if (isOpen) return
    }

    try {
      const res: CreateEngagementRes = await createEngagement()
      setLSItem('engagement_id', res.engagementID)
      setIsAgentMode(true)
      trackAgentConnection()
      addMessage([{ text: t('connecting_agent'), type: MessageToDisplay.TEXT, from: MODE.BOT }])
      handleWaitingInQueue(res)
    } catch (error) {
      handleEngagementError(error)
      throw 'error'
    }
  }

  const trackAgentConnection = () => {
    sendAnalyticsData({
      event_category: ANALYTICS_EVENTS.CONNECT_TO_AGENT,
    })
  }

  const handleEngagementError = (error: unknown) => {
    const apiError = error as ErrorResponse

    if (apiError?.status === 503) {
      addMessage([{ text: apiError?.message, type: MessageToDisplay.TEXT, from: MODE.BOT }])
      sendAnalyticsData({
        event_category: ANALYTICS_EVENTS.AGENT_UNAVAILABLE,
      })
    } else {
      addMessage([{ text: t('failed_connect_agent'), type: MessageToDisplay.TEXT, from: MODE.BOT }])
      sendAnalyticsData({
        event_category: ANALYTICS_EVENTS.UNAVAILABLE_CONNECT_AGENT,
      })
    }
    addButtons([{ title: t('start_over'), value: BUTTON_TYPE.START_OVER }])
  }

  const closeAgent = async () => {
    try {
      const isOpen = await isAgentOpen()
      if (!isOpen) {
        return
      }

      await sendMessage(BUTTON_TYPE.QUIT, { isMessageShown: false })
    } catch (error) {
      handleSendError(error)
    }
  }

  const fetchAgentMessages = async (): Promise<Message[]> => {
    if (isRequestInFlightRef.current) {
      return [] // Skip if a request is in flight
    }
    isRequestInFlightRef.current = true
    try {
      const engagementId = getLSItem('engagement_id')
      if (!engagementId) return []
      const response: GetMessagesRes = await getAgentMessages()
      if (response?.messages) {
        const messages: Message[] = response.messages.map((item) => {
          sendAnalyticsData({
            event_category: ANALYTICS_EVENTS.MESSAGE,
            message: item,
            from: MODE.AGENT,
            to: MODE.USER,
          })
          return {
            text: item,
            from: MODE.AGENT,
            type: MessageToSendBot.TEXT,
          }
        })

        return messages
      }
      return []
    } catch (error) {
      handleSendError(error)
      return []
    } finally {
      isRequestInFlightRef.current = false
    }
  }

  const handleSendError = (error: unknown) => {
    console.error('Failed to send message:', error)
  }

  return {
    closeAgent,
    sendMessage,
    handleAgent,
    fetchAgentMessages,
    isAgentMode,
  }
}
