import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { customer } from '../api/direct'
const languageFromHTML = document.documentElement.lang.includes('es') ? 'es' : 'en'
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    lng: languageFromHTML,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          greeting: "Hi! I'm here to help",
          close_icon: 'Hide the chat window',
          placeholder: 'Type your message here...',
          start_over: 'Start over',
          agent: 'Agent',
          agent_unavailable:
            'Looks like you need agent support. Unfortunately, our agents are unavailable at this time. Please contact us between  8:00am - 3:00am Eastern Time so we can further assist you.',
          closed_conversation_agent:
            "Sorry, this chat with the agent is currently closed. If you'd like to reconnect with an agent, please click on 'Connect'",
          closed_conversation_user: `Thank you for contacting ${customer}. In case you have additional inquiries please feel free to contact us.`,
          connecting_agent: 'Now connecting you with an agent.Type **quit** anytime to disconnect',
          failed_connect_agent:
            "We're sorry. We're unable to connect you with an agent. Please try again in 30 minutes.",
          waiting_in_queue: 'You are number {{number}} in the queue.',
          open_menu: 'Open menu',
          send_message: 'Send',
          close_widget: 'Hide the chat window',
          select_option: 'Select option',
          toggle: 'Toggle chatbot window',
          bot_icon: 'Bot icon',
          connect: 'Connect',
        },
      },
      es: {
        translation: {
          greeting: 'Hola! ¿Le puedo ayudar?',
          close_icon: 'Ocultar la ventana de chat',
          placeholder: 'Escribe tu mensaje aquí..',
          start_over: 'Reiniciar',
          agent: 'Agente',
          agent_unavailable:
            'Parece que necesita el apoyo de un agente. Desafortunadamente, nuestros agentes no están disponibles en este momento. Comuníquese con nosotros entre las 8 a. m. y las 3 a. m., hora del este, para que podamos ayudarlo.',
          closed_conversation_agent:
            "Lo sentimos, este chat con el agente está actualmente cerrado. Si desea volver a conectarse con un agente, haga clic en 'Conectar'",
          closed_conversation_user: `Gracias por contactar a ${customer}. Encaso de que tenga consultas adicionales, no dude en contactarnos nuevamente.`,
          connecting_agent:
            'Conectando con un agente. escriba **quit** en cualquier momento para desconectarse',
          failed_connect_agent:
            'Lo lamentamos. No pudimos conectarlo con un agente. Vuelva a intentarlo en 30 minutos.',
          waiting_in_queue: 'Eres el número {{number}} en la cola.',
          open_menu: 'Abrir menú',
          send_message: 'Enviar',
          close_widget: 'Ocultar la ventana de chat',
          select_option: 'Seleccionar opción',
          toggle: 'Alternar ventana del chatbot',
          bot_icon: 'Icono del bot',
          connect: 'Conectar',
        },
      },
    },
  })

export default i18n
